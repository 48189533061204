import { rapidReady, ylkStringToObj } from '../../../../lib/utils/rapid'
import Plugins from './plugins'

export default (() => {
  const categories = ['board', 'card', 'casino', 'puzzle', 'other']
  class GamesCategories {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]
      if (!this.elems.length) {
        return
      }
      this.description = document.querySelector('.m-channel-description')
      this.plugins = []
      this.navCarousel = document.querySelector('.games-categories')

      if (this.description) {
        this.catDesElem = [...this.description.querySelectorAll('.games-description')]
      }
      this.init()
    }

    init() {
      this.plugins.push(new Plugins.GlideCarousel(this.navCarousel, this))
      window.wafer.ready(() => {
        this.addWaferEvents()
      })

      window.addEventListener('popstate', e => {
        const urlPath = window.location.pathname
        const category = urlPath.split('/')[2]
        if (urlPath.startsWith('/games') && (!category || categories.indexOf(category) > -1)) {
          window.location.reload()
        }
      })
    }

    addWaferEvents() {
      window.wafer.on('fetch:success', event => {
        this.handleWaferTabSelected(event)
      })
    }

    /**
     * handleWaferTabFetch for a selected category
     * Handles the selection of a category
     * @param {Object} elem - Object with an 'elem' property, which is the DOM Element of the selected tab
     */
    handleWaferTabSelected(data = {}) {
      const {
        elem,
        elem: {
          dataset: { gamesCategory = '' }
        }
      } = data
      if (gamesCategory) {
        const { history } = window
        history.pushState(
          {
            state: history.state
          },
          '',
          `/games/${gamesCategory.toLowerCase() === 'all' ? '' : `${gamesCategory.toLowerCase()}/`}`
        )
        if (gamesCategory !== 'all') {
          window.wafer.state = {
            text: {
              value: `${gamesCategory[0].toUpperCase() + gamesCategory.slice(1)} Games`
            },
            style: {
              value: 'display:block'
            }
          }
        } else {
          window.wafer.state = {
            text: {
              value: `Games`
            },
            style: {
              value: 'display:none'
            }
          }
        }

        // unhide selected tab description and hide rest all description
        if (this.catDesElem) {
          this.catDesElem.forEach(el => {
            el.classList.contains(`${gamesCategory}`)
              ? (el.style.display = 'block')
              : (el.style.display = 'none')
          })
        }

        this.rapidBeacon(elem, elem)
      }
    }

    rapidBeacon(elem, target, overridesYlk = {}) {
      const { ylk } = target.dataset
      let ylkObj = ylkStringToObj(ylk)
      ylkObj = { ...ylkObj, ...overridesYlk }
      rapidReady(rapid => {
        rapid.beaconClick(ylkObj.sec, ylkObj.slk, ylkObj.itc, ylkObj)
      })
    }
  }
  return GamesCategories
})()
